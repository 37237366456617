import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

import {
  Button,
  Col,
  Container
} from "react-bootstrap";
import { withSwal } from "react-sweetalert2";
import PresaleTimer from "../../components/Presale/PresaleTimer";

function PreSale({ swal }) {
  const [bnbVal, setBnbVal] = useState("");
  const [usdtVal, setUsdtVal] = useState("");
  const [bnbBinanceRate, setBnbBinanceRate] = useState(0);
  const [roundValue, setRoundValue] = useState(0.02);
  const [calculatedAleit, setCalculatedAleit] = useState(0);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const connectMetaMask = () => {
    if (window.ethereum != undefined) {
      const accounts = window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => accounts)
        .then((data) => {
          console.log("%%%%%%%%%%%%%%%%%");
          console.log(data);
          console.log("%%%%%%%%%%%%%%%%%");
          setWalletAddress(data[0]);
          // swal.fire({
          //   icon: "success",
          //   title: "Congratulations",
          //   text: `Wallet Connected! ${data[0]}`,
          //   showCancelButton: true,
          //   confirmButtonText: "Ok",
          // });
          saveWalletAddress(data[0]);
          checkIsMetamaskAlreadyConnected();
        })
        .catch((err) => {
          console.log(typeof err.code);

          if (Number(err.code) === 4001) {
            swal.fire({
              icon: "warning",
              title: "Important!",
              text: `${err.message}`,
              showCancelButton: true,
              confirmButtonText: "Ok",
            });
          } else {
            console.error(err);
            if (Number(err.code) == -32002) {
              swal.fire({
                title: "Important",
                text: "Metamask connection is already in process. Please open metamask and complete connection.",
                icon: "error",
              });
            }
          }
        });

      console.log(accounts[0]);

      if (accounts[0] != undefined) {
        const account = accounts[0];
        swal
          .fire({
            title: "Congratulations!",
            text: `Wallet Connected: \n${account}`,
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(async (result) => {
            console.log(result);
            // isWalletAlreadySaved(account);
            if (result.isConfirmed) {
              try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x38" }],
                });
              } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                  try {
                    await window.ethereum.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                          chainId: "0x38",
                          chainName: "BNB Chain",
                          rpcUrls: [
                            "https://bsc-dataseed.binance.org/",
                          ] /* ... */,
                        },
                      ],
                    });
                  } catch (addError) {
                    console.log(addError.message);
                  }
                }
              }
            } else if (result.isDenied) {
            }
          });
      }
    } else {
      swal
        .fire({
          title: "Opps!",
          text: `Metamask extension required. Please click the Install button below to continue.`,
          icon: "error",
          confirmButtonText: "Install",
          showDenyButton: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            window.location.href =
              "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
          }
        });
    }
  };

  const saveWalletAddress = (wallet_address) => {
    axios({
      method: "post",
      url: "https://alleimran.com/api/metamask/storeAleituser",
      data: { wallet_address: wallet_address, user_type: "airdrop" },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        console.log(res);
        if (res.data == "already") {
          console.log("Already Added");
          swal
            .fire({
              title: "Congratulations!",
              text: "Wallet connected successfully.",
              icon: "success",
              confirmButtonText: "Go to Dashboard",
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location.href = "/dashboard";
              } else if (result.isDenied) {
              }
            });
        } else {
          if (res.data == "yes") {
            swal.fire({
              title: "Congratulations!",
              text: "Wallet connected successfully.",
              icon: "success",
            });
          } else {
            swal.fire({
              title: "Opps!",
              text: "Unable to connect with wallet.",
              icon: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log("NO CONNECTED ACCOUNT FOUND");
          } else {
            console.log("CONNECTED ACCOUNT FOUND");
            setWalletAddress(accounts[0]);
            setIsWalletConnected(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkIsMetamaskAlreadyConnected();
    }, 1000);
  }, []);

  return (
    <React.Fragment>
      <Container fluid id="home" className="pt-5" style={{paddingInline:'8%'}}>
        <div className="row" style={{ alignItems: "center" }}>
          <Col sm={12} md={7} className="mb-5">
            <div className="col-md-10">
              <h1
                className="White-Color-Text"
                style={{ fontFamily: "poppinsSemiBold", fontSize: "62px" }}
              >
                World's First Ultimate Food Investment Project OC2B
              </h1>

              <p
                className="Black-Color-Text"
                style={{ fontFamily: "poppinsRegular", textAlign: "justify" }}
              >
                ALEIT is more than just a food delivery platform. it is a
                visionary project that seeks to redefine the way people engage
                with food investment and loyalty programs. At its core, ALEIT
                aims to create a seamless ecosystem where every order becomes an
                opportunity for users to not only enjoy their favourite culinary
                delights but also reap significant financial rewards in the form
                of cashback. ALEIT Token empowers users, restaurants, and
                drivers alike, creating a mutually beneficial ecosystem that
                fosters growth, innovation, and prosperity."
              </p>
            </div>
            <div className="col-sm-12">
              <Button
                onClick={() =>
                  (window.location.href =
                    "https://aleits-organization.gitbook.io/aleit-whitepaper/")
                }
                className="codepen-button"
              >
                <span>White Paper</span>
              </Button>
            </div>
          </Col>
          <Col sm={12} md={5}>
            {isWalletConnected ? (
              <PresaleTimer
                connectMetaMask={connectMetaMask}
                isWalletConnected={true}
                airdrop={false}
                presale={true}
                activeBtns={false}
                walletAddress={walletAddress}
              />
            ) : (
              <PresaleTimer
                connectMetaMask={connectMetaMask}
                isWalletConnected={false}
                airdrop={false}
                presale={true}
                activeBtns={false}
                walletAddress={walletAddress}
              />
            )}
          </Col>
        </div>
        <div style={{ height: "20vh" }}></div>
      </Container>
    </React.Fragment>
  );
}

export default withSwal(PreSale);
