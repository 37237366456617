import React from "react";
import Lottie from "lottie-react";
import { Card, Col, Row } from "react-bootstrap";
import MasterLayout from "../master/MasterLayout";
import loadingDots from "../../../assets/animation/loadingdots.json";

const Buyaleit = () => {
  const comingsoon = require("../../../assets/images/comingsoon.jpeg");

  return (
    <MasterLayout>
      <Row className="mt-5">
        <Col md={12} sm={12} xs={12}>
          <h3 className="poppinsBold Yellow-Color-Text mb-5">Purchase ALEIT</h3>
        </Col>
        <Col xs={3}></Col>
        <Col xs={6}>
          <img src={comingsoon} className="img img-fluid rounded rounded-circle" />
        </Col>
        <Col xs={3}></Col>
      </Row>
    </MasterLayout>
  );
};

export default Buyaleit;
