import "bootstrap/dist/css/bootstrap.min.css";
import React, { useRef } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";
import { Link } from "react-router-dom";
import oooscilate from "../../assets/images/oooscillate.png";
import order from "../../assets/images/order.png";
import cashback from "../../assets/images/cashback.png";
import graphic from "../../assets/images/graphic.png";
import {
  Button,
  Card,
  Container,
  ProgressBar,
  Row,
  Col,
  Image,
  Stack,
} from "react-bootstrap";
import AnimationOnScroll from "../../components/AnimationOnScroll/AnimationOnScroll";
import { useMediaQuery } from "react-responsive";

function EchoSystem() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const echo1 = require("../../assets/images/echo1.png");
  const echo2 = require("../../assets/images/echo2.png");
  const echo3 = require("../../assets/images/echo3.png");
  const echo4 = require("../../assets/images/echo4.png");
  const echo5 = require("../../assets/images/echo5.png");
  const echo6 = require("../../assets/images/echo6.png");
  const echo7 = require("../../assets/images/echo7.png");
  const echo8 = require("../../assets/images/echo8.png");
  return (
    <React.Fragment>
      <Container id="echo"
        fluid
        className="m-0 p-0 py-5 px-0 "
        style={{
          backgroundImage: `url(${oooscilate})`,
          backgroundSize: "cover",
          backgroundPosition: isTabletOrMobile && "center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBlock: "40px",
          minHeight: '100vh',
        }}
      >
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          // xmlns:xlink="http://www.w3.org/1999/xlink"
          // xmlns:svgjs="http://svgjs.dev/svgjs"
          viewBox="0 0 1400 700"
          width="100%"
          height="auto"
          opacity="0.65"
          preserveAspectRatio="none"
        >
          <defs>
            <radialGradient id="ffflux-gradient">
              <stop offset="0%" stop-color="hsl(16, 51%, 77%)"></stop>
              <stop offset="100%" stop-color="hsl(275, 23%, 67%)"></stop>
            </radialGradient>
            <filter
              id="ffflux-filter"
              x="-20%"
              y="-20%"
              width="140%"
              height="140%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feTurbulence
                type="fractalNoise"
                baseFrequency="0.003 0.002"
                numOctaves="2"
                seed="294"
                stitchTiles="stitch"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                result="turbulence"
              ></feTurbulence>
              <feGaussianBlur
                stdDeviation="1 0"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                in="turbulence"
                edgeMode="duplicate"
                result="blur"
              ></feGaussianBlur>
              <feBlend
                mode="screen"
                x="0%"
                y="0%"
                width="100%"
                height="100%"
                in="SourceGraphic"
                in2="blur"
                result="blend"
              ></feBlend>
            </filter>
          </defs>
          <rect
            width="100%"
            height="100%"
            fill="url(#ffflux-gradient)"
            filter="url(#ffflux-filter)"
          ></rect>
        </svg>   */}
        {/* <Image src={oooscilate} alt="image" fluid /> */}
        <AnimationOnScroll direction={"downToUp"}>
          <Row
            xs={12}
            className="m-0 px-5 d-flex"
            // style={{ position: "relative", bottom: "500px" }}
          >
            <h1
              className="poppinsBold Gradient-Text my-4"
              style={{ textAlign: "center" }}
            >
              ECHOSYSTEM
            </h1>
            <Col xs={12} lg={4} className=" d-flex justify-content-center ">
              <Card className="blurEchoCard ">
                <Card.Img
                  alt="image"
                  src={order}
                  variant="top"
                  className="echoImage"
                />

                <Card.Body className="p-0 m-0 mt-5 p-3   ">
                  <Card.Title className="poppinsSemiBold  ">
                    1. Customer make Order
                  </Card.Title>
                  <Card.Text className="poppinsRegular resText">
                    Knowing your yearly gallons is a good indication of what
                    your usage is, but predicting what activity in your building
                    consumes how much and at what times, is the way of the
                    future.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} lg={4} className=" d-flex justify-content-center ">
              <Card className="blurEchoCard ">
                <Card.Img
                  alt="image"
                  src={cashback}
                  variant="top"
                  className="echoImage"
                />

                <Card.Body className="p-0 m-0 mt-5 p-3   ">
                  <Card.Title className="poppinsSemiBold  ">
                    2. Restaurant Give CASHBACK
                  </Card.Title>
                  <Card.Text className="poppinsRegular resText">
                    Knowing your yearly gallons is a good indication of what
                    your usage is, but predicting what activity in your building
                    consumes how much and at what times, is the way of the
                    future.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} lg={4} className=" d-flex justify-content-center ">
              <Card className="blurEchoCard ">
                <Card.Img
                  alt="image"
                  src={graphic}
                  variant="top"
                  className="echoImage"
                />

                <Card.Body className="p-0 m-0 mt-5 p-3   ">
                  <Card.Title className="poppinsSemiBold  ">
                    3. ALEIT PRICE GO UP
                  </Card.Title>
                  <Card.Text className="poppinsRegular resText">
                    Knowing your yearly gallons is a good indication of what
                    your usage is, but predicting what activity in your building
                    consumes how much and at what times, is the way of the
                    future.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </AnimationOnScroll>
      </Container>
    </React.Fragment>
  );
}

export default EchoSystem;
