// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// Images
import NotFound from "./layouts/error/NotFound";
import Dashboard from "./layouts/admin/dashboard/Dashboard";
import Home from "./layouts/Home";
import Airdrop from "./layouts/admin/airdrop/Airdrop";
import Presale from "./layouts/admin/presale/Presale";
import Buyaleit from "./layouts/admin/buyaleit/Buyaleit";
import Transactionhistory from "./layouts/admin/transactionhistory/Transactionhistory";
import LandingPage from "./layouts/LandingPage";



export default function App() {
 
  return (
    <Routes>
        <Route path="/" element={<LandingPage />} />
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/presale" element={<Presale />} />
        <Route path="/buyaleit" element={<Buyaleit />} />
        <Route path="/transactionhistory" element={<Transactionhistory />} />
        <Route path="*" element={<Navigate to="/notfound" />} />
      </Routes>
  );
}
