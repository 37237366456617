import React, { useCallback } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaBorderAll, FaParachuteBox, FaRegCreditCard } from "react-icons/fa";
import { FcDonate } from "react-icons/fc";
import { MdHistory, MdOutlineStore } from "react-icons/md";
import { AiOutlineDisconnect } from "react-icons/ai";

import { Link } from "react-router-dom";

const Sidebar = () => {
  const logo = require("../assets/images/logo512.png");
  const mainBackColor = "#212529";

  const disConnectMetaMask = async () => {
    const accounts = await window.ethereum.request({
      method: "wallet_revokePermissions",
      params: [
        {
          eth_accounts: {},
        },
      ],
    });
    window.location.href = "/";
  };

  return (
    <React.Fragment>
      <Card style={{ backgroundColor: "#16181C", height: "100vh" }}>
        <img
          src={logo}
          style={{
            height: "80px",
            width: "80px",
            alignSelf: "center",
            marginTop: "20px",
          }}
        />
        <h1
          className="poppinsBold Yellow-Color-Text"
          style={{ textAlign: "center", textDecoration: "none" }}
        >
          ALEIT
        </h1>
        <Container className="mt-3">
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <FaBorderAll size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Yellow-Color-Text"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </Col>
            </Row>
          </Card>
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <FaParachuteBox size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Yellow-Color-Text"
                  to="/airdrop"
                >
                  Airdrop
                </Link>
              </Col>
            </Row>
          </Card>
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <FcDonate size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Yellow-Color-Text"
                  to="/presale"
                >
                  Presale
                </Link>
              </Col>
            </Row>
          </Card>
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <FaRegCreditCard size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Yellow-Color-Text"
                  to="/buyaleit"
                >
                  Purchase ALEIT
                </Link>
              </Col>
            </Row>
          </Card>
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <MdHistory size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Yellow-Color-Text"
                  to="/transactionhistory"
                >
                  Transaction History
                </Link>
              </Col>
            </Row>
          </Card>
          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#212529" }}
          >
            <Row>
              <Col xs={2}>
                <AiOutlineDisconnect size={20} />
              </Col>
              <Col xs={10}>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    disConnectMetaMask();
                  }}
                  className="Yellow-Color-Text"
                >
                  Disconnect
                </Link>
              </Col>
            </Row>
          </Card>
        </Container>
      </Card>
    </React.Fragment>
  );
};

export default Sidebar;
