import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "../../App.css";
import { AiOutlineMail } from "react-icons/ai";
import { FaInstagramSquare, FaTelegram, FaTwitterSquare } from "react-icons/fa";

import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LiaCopyrightSolid } from "react-icons/lia";

function Footer() {
  const logo = require("../../assets/images/logo512.png");
  const TwitterIcon = require("../../assets/images/TwitterIcon.png");
  const cclogo = require("../../assets/images/cclogo.png");
  const coingabber = require("../../assets/images/coingabber.png");
  const SvgImage = require("../../assets/images/SvgImage.png");

  useEffect(() => {
    console.log("CALLED");
  }, []);
  return (
    <React.Fragment>
      <Container id="footer" fluid>
        <Row
          style={{
            // marginTop: "120px",
            paddingInline: '8%',
            paddingBottom:'5%',
            backgroundImage: `url(${SvgImage})`,
            backgroundSize: "cover",
            // aspectRatio:1,
            // backgroundPosition: isTabletOrMobile && "center",
            // width:'100%',
            height: "600px",
            alignItems: 'flex-end',
            // justifyContent: "flex-end",
            backgroundRepeat: "no-repeat",
            display: "flex",
          }}
        >
          <Col xs={12} md={3}>
            <img
              src={logo}
              style={{ height: "70px", width: "70px", marginBottom: 20 }}
            />{" "}
            <span
              className="poppinsBold Yellow-Color-Text"
              style={{ fontSize: "60px" }}
            >
              ALEIT
            </span>
            <h5 className="poppinsSemiBold White-Color-Text">
              <AiOutlineMail size={24} />
              {"  "}
              contact@aleit.live
            </h5>
            <h5
              className="poppinsSemiBold Yellow-Color-Text"
              style={{ textAlign: "left" }}
            >
              <LiaCopyrightSolid size={24} />
              2024 ALEIT
            </h5>
          </Col>
          <Col xs={12} md={3}>
            <h4
              style={{ marginTop: "30px" }}
              className="poppinsSemiBold White-Color-Text"
            >
              Stay Connected
            </h4>

            <Row className="mt-3">
              <Col xs={6}>
                <a target="_blank" href="https://twitter.com/aleitofficial">
                  <img
                    src={TwitterIcon}
                    style={{ width: "30px", height: "30px" }}
                  />
                  <a
                    target="_blank"
                    style={{ marginLeft: "20px" }}
                    href="https://t.me/aleitofficial"
                  >
                    <FaTelegram color="#F9B708" size={32} />
                  </a>
                </a>
              </Col>
              <Col xs={3}></Col>
              <Col xs={3}></Col>
            </Row>
          </Col>
          <Col xs={12} md={3}>
            <h4
              style={{ marginTop: "30px" }}
              className="poppinsSemiBold White-Color-Text"
            >
              LISTED ON
            </h4>
            <a
              target="_blank"
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
                // padding: 10,
                // marginLeft: "80px",
              }}
              href="https://coincodex.com/ico/aleit/"
            >
              <img src={cclogo} style={{ height: "20px" }} />
            </a>
            <br />
            <a
              target="_blank"
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
                // padding: 10,
                // marginLeft: "80px",
              }}
              href="https://www.coingabbar.com/en/crypto-ico-details/aleit-aleit-token-latest-ico-presale"
            >
              <img
                src={coingabber}
                style={{ height: "30px", marginTop: "20px" }}
              />
            </a>
          </Col>
          <Col xs={12} md={3}>
            <h4
              style={{ marginTop: "30px" }}
              className="poppinsSemiBold White-Color-Text"
            >
              QUICK LINKS
            </h4>
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#home"
            >
              Home
            </a>
            <br />
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#mission"
            >
              Our Mission
            </a>
            <br />
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#echo"
            >
              EchoSystem
            </a>
            <br />
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#roadmap"
            >
              Roadmap
            </a>
            <br />
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#tokenomics"
            >
              Tokenomics
            </a>
            <br />
            <a
              style={{
                color: "#FFFFFF",
                fontFamily: "poppinsRegular",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href="#faqs"
            >
              FAQs
            </a>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
