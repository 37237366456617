import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../App.css";

import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { withSwal } from "react-sweetalert2";
import axios from "axios";

function AleitNavbar({ swal }) {
  const logo = require("../assets/images/logo512.png");
  const [walletAddress, setWalletAddress] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const connectMetaMask = () => {
    if (window.ethereum != undefined) {
      const accounts = window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((accounts) => accounts)
        .then((data) => {
          console.log("%%%%%%%%%%%%%%%%%");
          console.log(data);
          console.log("%%%%%%%%%%%%%%%%%");
          setWalletAddress(data[0]);
          // swal.fire({
          //   icon: "success",
          //   title: "Congratulations",
          //   text: `Wallet Connected! ${data[0]}`,
          //   showCancelButton: true,
          //   confirmButtonText: "Ok",
          // });
          saveWalletAddress(data[0]);
          checkIsMetamaskAlreadyConnected();
        })
        .catch((err) => {
          console.log(typeof err.code);

          if (Number(err.code) === 4001) {
            swal.fire({
              icon: "warning",
              title: "Important!",
              text: `${err.message}`,
              showCancelButton: true,
              confirmButtonText: "Ok",
            });
          } else {
            console.error(err);
            if (Number(err.code) == -32002) {
              swal.fire({
                title: "Important",
                text: "Metamask connection is already in process. Please open metamask and complete connection.",
                icon: "error",
              });
            }
          }
        });

      console.log(accounts[0]);

      if (accounts[0] != undefined) {
        const account = accounts[0];
        swal
          .fire({
            title: "Congratulations!",
            text: `Wallet Connected: \n${account}`,
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then(async (result) => {
            console.log(result);
            // isWalletAlreadySaved(account);
            if (result.isConfirmed) {
              try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: "0x38" }],
                });
              } catch (switchError) {
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902) {
                  try {
                    await window.ethereum.request({
                      method: "wallet_addEthereumChain",
                      params: [
                        {
                          chainId: "0x38",
                          chainName: "BNB Chain",
                          rpcUrls: [
                            "https://bsc-dataseed.binance.org/",
                          ] /* ... */,
                        },
                      ],
                    });
                  } catch (addError) {
                    console.log(addError.message);
                  }
                }
              }
            } else if (result.isDenied) {
            }
          });
      }
    } else {
      swal
        .fire({
          title: "Opps!",
          text: `Metamask extension required. Please click the Install button below to continue.`,
          icon: "error",
          confirmButtonText: "Install",
          showDenyButton: true,
        })
        .then(async (result) => {
          if (result.value == true) {
            window.location.href =
              "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn";
          }
        });
    }
  };

  const saveWalletAddress = (wallet_address) => {
    axios({
      method: "post",
      url: "https://alleimran.com/api/metamask/storeAleituser",
      data: { wallet_address: wallet_address, user_type: "airdrop" },
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        console.log(res);
        if (res.data == "already") {
          console.log("Already Added");
          swal
            .fire({
              title: "Congratulations!",
              text: "Wallet connected successfully.",
              icon: "success",
              confirmButtonText: "Go to Airdrop",
            })
            .then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location.href = "/airdrop";
              } else if (result.isDenied) {
              }
            });
        } else {
          if (res.data == "yes") {
            swal.fire({
              title: "Congratulations!",
              text: "Wallet connected successfully.",
              icon: "success",
            });
          } else {
            swal.fire({
              title: "Opps!",
              text: "Unable to connect with wallet.",
              icon: "error",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log("NO CONNECTED ACCOUNT FOUND");
          } else {
            console.log("CONNECTED ACCOUNT FOUND");
            setWalletAddress(accounts[0]);
            setIsWalletConnected(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="pt-4" >
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} style={{ height: "70px", width: "70px" }} /><span className="poppinsSemiBold White-Color-Text">  OC2B</span> 
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#home"
              >
                Home
              </Nav.Link>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#mission"
              >
                Our Mission
              </Nav.Link>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#echo"
              >
                EchoSystem
              </Nav.Link>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#roadmap"
              >
                Roadmap
              </Nav.Link>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#tokenomics"
              >
                Tokenomics
              </Nav.Link>
              <Nav.Link
                style={{
                  color: "#FFFFFF",
                  fontFamily: "poppinsRegular",
                  fontSize: "14px",
                }}
                href="#faqs"
              >
                FAQs
              </Nav.Link>
              <Button
                onClick={() => {
                  connectMetaMask();
                }}
                className="btn btn-warning rounded-5"
                style={{
                  height: "35px",
                  width: "150px",
                  fontSize: "12px",
                }}
              >
                <span className="poppinsBold White-Color-Text">
                  {" "}
                  Join Airdrop
                </span>
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Navbar
      className="m-0 px-5"
      sticky="top"
      expand="lg"
      style={{ backgroundColor: "red" }}
    >
      <Navbar.Brand
        style={{
          color: "#FFFFFF",
          fontFamily: "poppinsBold",
          // paddingLeft: "50px",
          fontSize: "40px",
          backgroundColor:'yellow'
        }}
        href="#home"
      >
        <img src={logo} style={{ height: "50px", width: "50px" }} /> AL-E-IT
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" style={{backgroundColor:'pink' }}>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto"></Nav>
        <Nav className="me-auto">
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#home"
          >
            Home
          </Nav.Link>
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#mission"
          >
            Our Mission
          </Nav.Link>
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#echo"
          >
            EchoSystem
          </Nav.Link>
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#roadmap"
          >
            Roadmap
          </Nav.Link>
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#tokenomics"
          >
            Tokenomics
          </Nav.Link>
          <Nav.Link
            style={{
              color: "#FFFFFF",
              fontFamily: "poppinsRegular",
              fontSize: "14px",
            }}
            href="#faqs"
          >
            FAQs
          </Nav.Link>
          <Button
            onClick={() => {
              connectMetaMask();
            }}
            className="btn btn-warning rounded-5"
            style={{
              height: "35px",
              width: "150px",
              fontSize: "12px",
            }}
          >
            <span className="poppinsBold White-Color-Text"> Join Airdrop</span>
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar> */}
    </>
  );
}

export default withSwal(AleitNavbar);
