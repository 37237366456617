import React from "react";
import { motion, useScroll } from "framer-motion";
import { Container } from "react-bootstrap";

const AnimationOnScroll = ({ children ,direction}) => {
  const { scrollYProgress, scrollXProgress } = useScroll();
  const getInitialPosition = () => {
    if (direction === "leftToRight") {
      return { opacity: 0, x: -40 }; // Start from the left
    } else if (direction === "rightToLeft") {
      return { opacity: 0, x: 50 }; // Start from the right
    } else {
      return { opacity: 0, y: 50 }; // Default down-to-up
    }
  };
  const getFinalPosition = () => {
    if (direction === "leftToRight" || direction === "rightToLeft") {
      return { opacity: 1, x: 0 }; // Final position for horizontal animations
    } else {
      return { opacity: 1, y: 0 }; // Final position for vertical animations
    }
  };

  return (
    <Container fluid className="m-0 p-0">
      <motion.div
        // style={{ scaleX: scrollYProgress, }}
        // animate="visible"
        // animate={{ opacity: 1, y: 0 }}
        // initial="hidden"
        // whileInView="visible"
        // initial={{ opacity: 0 }}
        // whileInView={{ opacity: 1 }}
        // initial={{ opacity: 0, y: 50 }}
        // whileInView={{ opacity: 1, y: 0 }}
        initial={getInitialPosition()}
        whileInView={getFinalPosition()}
        // viewport={{ once: true }}
        transition={{ duration: 1.5, 
          ease: 'easeOut',
          type: "spring",
          stiffness:100,delay:0.25
          
         }}
         //   variants={{
        //     active: {
        //       backgroundColor: "red"
        //   },
        //   inactive: {
        //     backgroundColor: "blue",
        //     transition: { duration: 2 }
        //   }
        //  } }
        // variants={{
        //   hidden: { opacity: 0, x: 20 },
        //   visible: {
        //     opacity: 1,
        //     y: 0,
        //     transition: { type: "easeOut", stiffness: 100 },
        //   },
        // }}
      >
        {children}
      </motion.div>
    </Container>
  );
};

export default AnimationOnScroll;
