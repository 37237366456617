import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "../../App.css";
import Faq from "react-faq-component";

import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";

function Faqs() {
  const missionImage = require("../../assets/images/mission.png");
  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: <p className="poppinsBold ">What is ALEIT?</p>,
        content: (
          <p className="poppinsRegular">
            ALEIT is a revolutionary food investment project that integrates
            cryptocurrency into the food delivery experience. It offers users
            the opportunity to earn cashback rewards in the form of ALEIT tokens
            with every purchase, creating a seamless ecosystem where food and
            finance converge.
          </p>
        ),
      },
      {
        title: <p className="poppinsBold ">How does ALEIT work?</p>,
        content: (
          <p className="poppinsRegular">
            ALEIT works by leveraging blockchain technology to tokenize cashback
            rewards within the food delivery industry. Users earn ALEIT tokens
            as cashback rewards for their purchases, which can be used for
            various purposes within the ALEIT ecosystem, including trading,
            staking, and liquidity provision that automatically causing get
            their money back.
          </p>
        ),
      },
      {
        title: (
          <p className="poppinsBold ">What are the benefits of using ALEIT?</p>
        ),
        content: (
          <p className="poppinsRegular">
            As users are going to enjoy the cashback rewards on every purchase,
            participating in gaining exposure to the potential growth of the
            ALEIT token. Staking ALEIT for a week or month could get their whole
            money back of their purchase.
          </p>
        ),
      },
      {
        title: <p className="poppinsBold ">How can I earn ALEIT tokens?</p>,
        content: (
          <p className="poppinsRegular">
            There are several ways to earn ALEIT tokens, including making
            purchases through the ALEIT platform, participating in airdrop
            campaigns, and engaging with the ALEIT community through social
            media channels. Additionally, users can earn rewards by referring
            friends and family to the platform.
          </p>
        ),
      },
      {
        title: <p className="poppinsBold ">What can I do with ALEIT tokens?</p>,
        content: (
          <p className="poppinsRegular">
            ALEIT tokens can be used for various purposes within the ecosystem,
            including trading on cryptocurrency exchanges, staking to earn
            additional rewards, providing liquidity to decentralized exchanges,
            and participating in governance decisions. Users can also use ALEIT
            tokens to redeem rewards and discounts from partner restaurants.
          </p>
        ),
      },
      {
        title: <p className="poppinsBold ">Is ALEIT safe and secure to use?</p>,
        content: (
          <p className="poppinsRegular">
            Certainly! The ALEIT platform utilizes blockchain technology,
            offering all users the opportunity to reclaim their money by staking
            it. The entire ecosystem is secure and free from any potential risks
          </p>
        ),
      },
    ],
  };

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "#4A556B",
    rowContentColor: "#8B99B9",
    arrowColor: "#4A556B",
    rowContentPaddingLeft: "10px",
    // arrowColor: "red",
    transitionDuration: "0.3s",
    timingFunc: "ease"
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };
  useEffect(() => {
    console.log("CALLED");
  }, []);
  return (
    <React.Fragment>
      <Container id="faqs">
        <Row>
          <Col xs={12} md={12}>
            <h1
              className="poppinsExtraBold Gradient-Text mb-5"
              style={{ textAlign: "center", marginTop: "10%" }}
            >
              Frequently Asked Questions
            </h1>
            <Faq className={'faq-row faq-title '} data={data} styles={styles} config={config} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Faqs;
