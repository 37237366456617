import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaParachuteBox } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { PiChartLineUpDuotone } from "react-icons/pi";
import { FcDonate } from "react-icons/fc";
import { FaRepeat } from "react-icons/fa6";
import MasterLayout from "../master/MasterLayout";
import { Link } from "react-router-dom";
import { LiaWalletSolid } from "react-icons/lia";
import axios from "axios";
import { withSwal } from "react-sweetalert2";

const Dashboard = ({ swal }) => {
  //
  const [airdropAleit, setAirdropAleit] = useState("");
  const [presaleRound, setPresaleRound] = useState("");
  const [roundRate, setRoundRate] = useState("");
  const [presaleAmount, setPresaleAmount] = useState("");
  const [presaleAleit, setPresaleAleit] = useState("");
  const [currentGrowth, setCurrentGrowth] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [noParticipationMessage, setNoParticipationMessage] = useState("");

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log(
              "*******************************************************"
            );
            console.log("NO CONNECTED ACCOUNT FOUND");
            console.log(
              "*******************************************************"
            );
          } else {
            console.log("CONNECTED ACCOUNT FOUND");
            setWalletAddress(accounts[0]);
            // if()
            getAleitDashboardStats(accounts[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getAleitDashboardStats = async (walletAddress) => {
    axios
      .post("https://alleimran.com/api/metamask/getAleitDashboardStats", {
        wallet_address: walletAddress,
      })
      .then((resp) => {
        if (resp.data.hasOwnProperty("error")) {
          setNoParticipationMessage(
            "You have not participated in any presale yet"
          );
        } else {
          setAirdropAleit(resp.data.airdropAleit);
          setPresaleRound(resp.data.presaleRound);
          setRoundRate(resp.data.presaleRoundRate);
          setPresaleAmount(resp.data.presaleAmount);
          setPresaleAleit(resp.data.presaleAleit);
          setCurrentGrowth(resp.data.currentGrowth);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkIsMetamaskAlreadyConnected();
  }, []);

  return (
    <MasterLayout>
      <Row className="mt-5">
        <Col md={6} sm={12} xs={12}>
          <h3 className="poppinsBold Yellow-Color-Text mb-5">Dashboard</h3>

          <Card
            className="poppinsRegular Yellow-Color-Text p-2 mt-2"
            style={{ backgroundColor: "#16181C" }}
          >
            <Row>
              <Col xs={12}>
                <LiaWalletSolid size={20} style={{ marginBottom: 3 }} />
                <span
                  className="Yellow-Color-Text"
                  style={{ textDecoration: "none", marginLeft: 15 }}
                >
                  Wallet Address
                </span>

                <h4 style={{ marginTop: 10 }}>
                  {walletAddress == "" || walletAddress == null
                    ? "Not connected to wallet!"
                    : walletAddress}
                </h4>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {noParticipationMessage ? (
        <Row className="mt-2">
          <Col md={12} sm={12} xs={12}>
            <Card
              className="poppinsRegular Yellow-Color-Text p-2 mt-2"
              style={{
                backgroundColor: "#16181C",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h4>{noParticipationMessage}</h4>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mt-2">
            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <img
                      src={require("../../../assets/images/logo512.png")}
                      style={{ height: 20, width: 20 }}
                    />
                    <Link
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                      className="Yellow-Color-Text"
                      to="/dashboard"
                    >
                      Airdrop ALEIT
                    </Link>

                    <Row>
                      <Col xs={8}>
                        <h4 style={{ marginTop: 10, color: "white" }}>
                          {airdropAleit}
                        </h4>
                      </Col>
                      <Col xs={4}>
                        <Button
                          className="btn btn-warning"
                          onClick={() => {
                            swal.fire({
                              title: "Important",
                              text: "Stay tuned till listing on DEX or CEX.",
                              icon: "warning",
                            });
                          }}
                        >
                          CLAIM
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <FaRepeat size={17} style={{ marginBottom: 3 }} />
                    <span
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                    >
                      Presale Round
                    </span>

                    <h4 style={{ marginTop: 10, color: "white" }}>
                      #{" "}
                      {presaleRound == "" || presaleRound == null
                        ? 0
                        : presaleRound}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <FcSalesPerformance size={16} style={{ marginBottom: 3 }} />
                    <span
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                    >
                      Round Rate
                    </span>

                    <h4 style={{ marginTop: 10, color: "white" }}>
                      $ {roundRate}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <img
                      src={require("../../../assets/images/logo512.png")}
                      style={{ height: 20, width: 20 }}
                    />
                    <Link
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                      className="Yellow-Color-Text"
                      to="/dashboard"
                    >
                      Presale ALEIT
                    </Link>
                    <Row>
                      <Col xs={8}>
                        <h4 style={{ marginTop: 10, color: "white" }}>
                          {presaleAleit}{" "}
                        </h4>
                      </Col>
                      <Col xs={4}>
                        <Button
                          className="btn btn-warning"
                          onClick={() => {
                            swal.fire({
                              title: "Important",
                              text: "Stay tuned till listing on DEX or CEX.",
                              icon: "warning",
                            });
                          }}
                        >
                          CLAIM
                        </Button>
                      </Col>
                    </Row>
                    
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <FcDonate size={16} style={{ marginBottom: 3 }} />
                    <span
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                    >
                      Invested Money
                    </span>
                    <h4 style={{ marginTop: 10, color: "white" }}>
                      $ {Number(presaleAmount).toFixed(2)}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={4} sm={12} xs={12}>
              <Card
                className="poppinsRegular Yellow-Color-Text p-2 mt-2"
                style={{ backgroundColor: "#16181C" }}
              >
                <Row>
                  <Col xs={12}>
                    <PiChartLineUpDuotone
                      size={19}
                      style={{ marginBottom: 3 }}
                    />
                    <span
                      style={{
                        textDecoration: "none",
                        marginLeft: 15,
                        fontSize: 16,
                      }}
                    >
                      Current Growth{" "}
                    </span>

                    <h4 style={{ marginTop: 10, color: "white" }}>
                      $ {Number(currentGrowth).toFixed(2)}{" "}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </MasterLayout>
  );
};

export default withSwal(Dashboard);
