import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../App.css";

import AleitNavbar from "../Screens/AleitNavbar";
import PreSale from "../Screens/PreSale/PreSale";
import MissionStatement from "../Screens/Mission/MissionStatement";
import EchoSystem from "../Screens/EchoSystem/EchoSystem";
import backgroundSvg from "../assets/images/backgroundSvg.png";
import { useMediaQuery } from "react-responsive";
import UpcomingExchanges from "../Screens/Exchanges/UpcomingExchanges";
import TargetedExchanges from "../Screens/Exchanges/TargetedExchanges";
import RoadMap from "../Screens/RoadMap/RoadMap";
import TokenSale from "../Screens/TokenSale/TokenSale";
import Faqs from "../Screens/Faqs/Faqs";
import Footer from "../Screens/Footer/Footer";

function LandingPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 475px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <svg
            width="100%"
            height="auto"
            id="svg"
            viewBox="0 0 1440 1100"
            xmlns="http://www.w3.org/2000/svg"
            className="transition duration-300 ease-in-out delay-150"
            transform="scale(1,-1)"
          >
            <style>
              {`
      .path-1 {
        animation: pathAnim-1 20s linear infinite;
      }
      @keyframes pathAnim-1 {
        0% {
          d: path("M 0,1100 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z");
        }
         
          25% {
          d: path("M 0,1100 L 0,408 C 103.17948717948718,443.8205128205128 206.35897435897436,479.64102564102564 286,482 C 365.64102564102564,484.35897435897436 421.7435897435897,453.2564102564103 504,434 C 586.2564102564103,414.7435897435897 694.6666666666666,407.3333333333333 791,438 C 887.3333333333334,468.6666666666667 971.5897435897435,537.4102564102565 1048,530 C 1124.4102564102565,522.5897435897435 1192.974358974359,439.02564102564105 1272,418 C 1351.025641025641,396.97435897435895 1440,438.4871794871795 1530,480 L 1440,1200 L 0,1200 Z");
        }
       
         50% {
          d: path("M 0,1100 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z");
        }
           75% {
          d: path("M 0,1100 L 0,408 C 64.94615384615386,444.5358974358974 129.89230769230772,481.07179487179485 223,471 C 316.1076923076923,460.92820512820515 437.37692307692305,404.24871794871797 520,414 C 602.623076923077,423.75128205128203 646.6,499.93333333333334 711,486 C 775.4,472.06666666666666 860.2230769230769,368.0179487179487 943,342 C 1025.7769230769231,315.9820512820513 1106.5076923076924,367.99487179487176 1189,392 C 1271.4923076923076,416.00512820512824 1355.746153846154,412.0025641025641 1440,408 L 1440,1200 L 0,1200 Z");
        }
        
        100% {
          d: path("M 0,1100 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z");
        }
      }
    `}
            </style>
            <g>
              <path
                d="M 0,1200 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z"
                fill="url(#gradient1)"
                className="path-1"
              />

              <defs>
                <linearGradient
                  id="gradient1"
                  x1="0%"
                  y1="0%"
                  x2="80%"
                  y2="70%"
                >
                  <stop offset="0%" stopColor="#CACAEE" />
                  <stop offset="50%" stopColor="#D7CCDF" />
                  <stop offset="100%" stopColor="#E2B5A5" />
                </linearGradient>
              </defs>
            </g>
          </svg>
          <svg
            style={{
              width: "100%",
              height: "auto",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
            width="100%"
            height="auto"
            id="svg"
            viewBox="0 0 1400 990"
            xmlns="http://www.w3.org/2000/svg"
            className="transition duration-300 ease-in-out delay-150"
            transform="scale(1,-1)"
            preserveAspectRatio="'none"
          >
            <style>
              {`
      .path-2 {
        animation: pathAnim-2 10s linear infinite;
      }
      @keyframes pathAnim-2 {
        0% {
          d: path("M 0,990 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z");
        }
        25% {
          d: path("M 0,990 L 0,408 C 64.94615384615386,444.5358974358974 129.89230769230772,481.07179487179485 223,471 C 316.1076923076923,460.92820512820515 437.37692307692305,404.24871794871797 520,414 C 602.623076923077,423.75128205128203 646.6,499.93333333333334 711,486 C 775.4,472.06666666666666 860.2230769230769,368.0179487179487 943,342 C 1025.7769230769231,315.9820512820513 1106.5076923076924,367.99487179487176 1189,392 C 1271.4923076923076,416.00512820512824 1355.746153846154,412.0025641025641 1440,408 L 1440,1200 L 0,1200 Z");
        }
        50% {
          d: path("M 0,990 L 0,408 C 90.25897435897437,443.0282051282052 180.51794871794874,478.0564102564103 259,489 C 337.48205128205126,499.9435897435897 404.18717948717946,486.8025641025641 502,451 C 599.8128205128205,415.1974358974359 728.7333333333333,356.7333333333333 821,371 C 913.2666666666667,385.2666666666667 968.8794871794872,472.2641025641025 1054,479 C 1139.1205128205128,485.7358974358975 1253.7487179487179,412.2102564102564 1338,386 C 1422.2512820512821,359.7897435897436 1476.125641025641,386.8948717948718 1530,414 L 1440,1200 L 0,1200 Z");
        }
        75% {
          d: path("M 0,990 L 0,408 C 103.17948717948718,443.8205128205128 206.35897435897436,479.64102564102564 286,482 C 365.64102564102564,484.35897435897436 421.7435897435897,453.2564102564103 504,434 C 586.2564102564103,414.7435897435897 694.6666666666666,407.3333333333333 791,438 C 887.3333333333334,468.6666666666667 971.5897435897435,537.4102564102565 1048,530 C 1124.4102564102565,522.5897435897435 1192.974358974359,439.02564102564105 1272,418 C 1351.025641025641,396.97435897435895 1440,438.4871794871795 1530,480 L 1440,1200 L 0,1200 Z");
        }
        100% {
          d: path("M 0,990 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z");
        }
      }
    `}
            </style>
            <g>
              <path
                d="M 0,1200 L 0,408 C 72.75641025641025,389.5076923076923 145.5128205128205,371.0153846153847 216,367 C 286.4871794871795,362.9846153846153 354.7051282051282,373.44615384615383 440,374 C 525.2948717948718,374.55384615384617 627.6666666666666,365.20000000000005 722,390 C 816.3333333333334,414.79999999999995 902.6282051282051,473.75384615384615 980,463 C 1057.371794871795,452.24615384615385 1125.820512820513,371.7846153846154 1201,351 C 1276.179487179487,330.2153846153846 1358.0897435897436,369.10769230769233 1440,408 L 1440,1200 L 0,1200 Z"
                fill="url(#gradient2)"
                className="path-2"
              />

              <defs>
                <linearGradient
                  id="gradient2"
                  x1="0%"
                  y1="0%"
                  x2="80%"
                  y2="70%"
                >
                  <stop offset="0%" stopColor="#8284D9" opacity={0.1} />
                  <stop offset="50%" stopColor="#AF99BF" opacity={0.1} />
                  <stop offset="100%" stopColor="#E2B5A5" opacity={0.1} />
                </linearGradient>
              </defs>
            </g>
          </svg>
        </>
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          height: "auto",
        }}
      >
        <div className={`${isMobile && "Gradient-Background parallelograph"}`}>
          <AleitNavbar />
          <PreSale />
        </div>
        <MissionStatement />
        <EchoSystem />
        <UpcomingExchanges />
        <TargetedExchanges />
        <RoadMap />
        <TokenSale />
        <Faqs />
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
