import React, { useCallback, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../../components/Sidebar";

const MasterLayout = ({children}) => {


  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log("NOT CONNECTED");
            window.location.href = "/";
          } else {
            console.log("CONNECTED ACCOUNT FOUND");
            
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(()=>{
    checkIsMetamaskAlreadyConnected();
  },[]);
  
  return (
    <div style={{backgroundColor:"#212529"}}>
      <Container fluid style={{backgroundColor:"#212529"}}>
        <Row>
          <Col xl={3} lg={3} md={3}>
              <Sidebar />  
          </Col>
          <Col xl={9} lg={9} md={9} style={{paddingTop:"50px"}}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MasterLayout;
