import React, { useEffect, useState } from "react";
import MasterLayout from "../master/MasterLayout";
import { Button, Col, Row } from "react-bootstrap";
import PresaleTimer from "../../../components/Presale/PresaleTimer";
import LiquidityChart from "../../../components/LiquidityLogic/LiquidityChart";
import { FaXTwitter, FaTelegram } from "react-icons/fa6";
import SweetAlert2, { withSwal } from "react-sweetalert2";
import ReactLoading from "react-loading";

import axios from "axios";

const Airdrop = ({ swal }) => {
  const [bnbVal, setBnbVal] = useState("");
  const [usdtVal, setUsdtVal] = useState("");
  const [bnbBinanceRate, setBnbBinanceRate] = useState(0);

  const [calculatedAleit, setCalculatedAleit] = useState(0);
  const [bnbOrUsdt, setBnbOrUsdt] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [airdropLoading, setAirdropLoading] = useState(false);

  const [isTwitterFollowed, setIsTwitterFollowed] = useState(false);
  const [isTwitterPostTweeted, setIisTwitterPostTweeted] = useState(false);
  const [isTelegramJoined, setIsTelegramJoined] = useState(false);

  const [presaleRound, setPresaleRound] = useState("");
  const [roundRate, setRoundRate] = useState("");
  const [currentTarget, setCurrentTarget] = useState("");

  const createTransaction = (airdropOrPresale) => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      })
      .then((result) => {
        console.log(result);
        window.ethereum
          .request({
            method: "eth_sendTransaction",
            params: [
              {
                from: `${walletAddress}`, // The user's active address.
                to: airdropOrPresale == "presale" ? "0x2d5CDB84045d009276a0c2E30977ba45057a2A07": "0x79Ddc03d3288A6290b8C4B220e22fA81B1f98F32",
                value:
                  airdropOrPresale == "presale"
                    ? Number(bnbVal * 1000000000000000000).toString(16)
                    : Number(3200000000000000).toString(16),
                gas: Number(21000).toString(16),
              },
            ],
          })
          .then((txHash) => {
            console.log(txHash);
            storeTransactionHash(txHash, airdropOrPresale);
          })
          .catch((error) => {
            if (error.code == 4001) {
              setAirdropLoading(false);
              swal.fire({
                title: "Important",
                text: "You have denied the transaction.",
                icon: "error",
              });
            }
          });
      })
      .catch((error) => {
        if (error.code === 4902) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x38",
                  chainName: "BNB Smart Chain Testnet",
                  rpcUrls: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545/"],
                  nativeCurrency: {
                    decimals: 18,
                    symbol: "tBNB",
                  },
                },
              ],
              // params: [
              //   {
              //     chainId: '0x38',
              //     chainName: 'BNB Chain',
              //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
              //     nativeCurrency:{
              //         decimals:18,
              //         symbol:"BNB"
              //     }
              //   },
              // ],
              // params: [
              //   {
              //     chainId: '0x38',
              //     chainName: 'BNB Chain',
              //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
              //     nativeCurrency:{
              //         decimals:18,
              //         symbol:"BNB"
              //     }
              //   },
              // ],
            })
            .then(() => {})
            .catch((error) => {
              setAirdropLoading(false);
              console.log(error.message);
            });
        }
      });
  };

  const storeTransactionHash = (txHash, user_type) => {
    console.log(walletAddress[0]);
    console.log(user_type);
    console.log(txHash);

    let apidata = {};
    if (user_type == "presale") {
      apidata = {
        wallet_address: walletAddress[0],
        total_aleit: 0,
        presale_aleit: Math.round((bnbBinanceRate * bnbVal) / roundRate),
        bnb_rate: bnbBinanceRate,
        value_in_bnb: bnbVal,
        presale_amount:
          user_type == "presale"
            ? Number(bnbBinanceRate * bnbVal).toFixed(2)
            : 1,
        txHash: txHash,
        presale_round: presaleRound,
        target_round: currentTarget,
        round_value: roundRate,
        buying_rate: roundRate,
        user_type: user_type,
        status: "pending",
        payment_method: "bnb",
      };
    } else {
      apidata = {
        wallet_address: walletAddress[0],
        total_aleit: 500,
        presale_amount: null,
        txHash: txHash,
        buying_rate: roundRate,
        user_type: user_type,
        status: "pending",
        payment_method: "bnb",
        current_round: 1,
        current_round_value: roundRate,
        bnb_rate: bnbBinanceRate,
        value_in_bnb: bnbVal,
      };
    }

    axios({
      method: "post",
      url: "https://alleimran.com/api/metamask/storeTransactionDetails",
      data: apidata,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        console.log(res);
        setAirdropLoading(false);
        if (res.data == "yes") {
          swal.fire({
            title: "Congratulations!",
            text:
              user_type == "presale"
                ? "Successfully Participated in Presale. Please check dashboard."
                : "Successfully approved your Airdrop. Please check dashboard.",
            icon: "success",
          });
        } else {
          swal.fire({
            title: "Alas!",
            text: "Unable to hold your position in presale.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        swal.fire({
          title: "Opps!",
          text: `Your record was unable to save due to technical issue. Please take screenshot and generate a ticket.
            ${"\n"}Total Purchase : ${bnbVal}
            ${"\n"}BNB RATE : ${bnbBinanceRate}
            ${"\n"}Wallet : ${walletAddress[0]} `,
          icon: "error",
        });
        console.log(error);
      });
  };

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log("NO CONNECTED ACCOUNT FOUND");
          } else {
            console.log("CONNECTED ACCOUNT FOUND AIRDROP");

            console.log("*****************************");
            console.log(accounts[0]);
            setWalletAddress(accounts);
            console.log("*****************************");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    checkIsMetamaskAlreadyConnected();
  }, []);

  return (
    <MasterLayout>
      <Row className="mt-5">
        <Col md={12} sm={12} xs={12} className="mb-5">
          <h3 className="poppinsBold Yellow-Color-Text mb-5">AIRDROP QUESTS</h3>
          <Row>
            <Col xs={12}>
              <h5 className="poppinsSemiBold White-Color-Text mb-4">
                STEP 1 : Follow Us on Twitter
              </h5>
            </Col>
            <Col xs={12}>
              <Button
                onClick={() => {
                  setIsTwitterFollowed(true);
                  window.open(
                    "https://twitter.com/intent/user?screen_name=aleitofficial",
                    "_blank"
                  );
                }}
                className="poppinsBold White-Color-Text btn-warning"
                style={{
                  backgroundColor: "black",
                  borderRadius: "100px",
                  textDecoration: "none",
                  color: "#FFC007",
                  padding: "10px 15px",
                }}
              >
                <FaXTwitter /> &nbsp;Follow &nbsp;@aleitofficial
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              <h5 className="poppinsSemiBold White-Color-Text mb-4">
                STEP 2 : Re-Tweet the pin post
              </h5>
            </Col>
            <Col xs={12}>
              <Button
                onClick={() => {
                  setIisTwitterPostTweeted(true);
                  window.open(
                    "https://twitter.com/intent/retweet?tweet_id=1771607687169470594",
                    "_blank"
                  );
                }}
                className="poppinsBold White-Color-Text btn-warning"
                style={{
                  backgroundColor: "black",
                  borderRadius: "100px",
                  textDecoration: "none",
                  color: "#FFC007",
                  padding: "10px 15px",
                }}
                // href="https://twitter.com/babarazam258/status/1767185583166628305?t=SaRdlqFBwTi8GcR8tHVIhA&s=19"
              >
                <FaXTwitter /> &nbsp;Post &nbsp;@aleitofficial
              </Button>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12}>
              <h5 className="poppinsSemiBold White-Color-Text mb-4">
                STEP 3 : Join us on Telegram
              </h5>
            </Col>
            <Col xs={12}>
              <Button
                onClick={() => {
                  setIsTelegramJoined(true);
                  window.open("https://t.me/aleitofficial", "_blank");
                }}
                className="poppinsBold White-Color-Text btn-warning"
                style={{
                  backgroundColor: "black",
                  borderRadius: "100px",
                  textDecoration: "none",
                  color: "#FFC007",
                  padding: "10px 15px",
                }}
              >
                <FaTelegram /> &nbsp;Join &nbsp;@aleitofficial
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              {/* <h5 className="poppinsSemiBold White-Color-Text mb-4">
                STEP 4 : Pay 0.0032 BNB to hold your position in airdrop.
              </h5> */}
            </Col>
            <Col xs={12}>
              {airdropLoading ? (
                <div style={{ paddingLeft: "40%" }}>
                  <ReactLoading
                    type="bubbles"
                    color="#F9B708"
                    height={64}
                    width={64}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    if (isTwitterFollowed == false) {
                      swal.fire({
                        title: "Important",
                        text: "Follow Us On Twitter first.",
                        icon: "error",
                      });
                    } else if (isTwitterPostTweeted == false) {
                      swal.fire({
                        title: "Important",
                        text: "Re-tweet our pin post first.",
                        icon: "error",
                      });
                    } else if (isTelegramJoined == false) {
                      swal.fire({
                        title: "Important",
                        text: "Join our telegram channel first",
                        icon: "error",
                      });
                    } else {
                      setAirdropLoading(true);
                      createTransaction("airdrop");
                    }
                  }}
                  className="btn btn-warning rounded-5 mt-2"
                  style={{
                    height: "43px",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  <span className="poppinsSemiBold White-Color-Text">
                    {" "}
                    CLAIM AIRDROP
                  </span>
                </Button>
              )}
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={12}>
              <p className="poppinsSemiBold mb-4" style={{ color: "red" }}>
                Important note: All of your actions will be verified at the time
                of claim airdrop. Any quest missing could cause of losing
                airdrop.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </MasterLayout>
  );
};

export default withSwal(Airdrop);
