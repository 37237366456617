import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useMemo, useState } from "react";
import "../App.css";

import AleitNavbar from "../Screens/AleitNavbar";
import EchoSystem from "../Screens/EchoSystem/EchoSystem";
import MissionStatement from "../Screens/Mission/MissionStatement";
import PreSale from "../Screens/PreSale/PreSale";
import RoadMap from "../Screens/RoadMap/RoadMap";
import TokenSale from "../Screens/TokenSale/TokenSale";

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import Faqs from "../Screens/Faqs/Faqs";
import UpcomingExchanges from "../Screens/Exchanges/UpcomingExchanges";
import TargetedExchanges from "../Screens/Exchanges/TargetedExchanges";
import Stages from "../Screens/Stages/Stages";
import Footer from "../Screens/Footer/Footer";

function Home() {
  //
  const [init, setInit] = useState(false);

  useEffect(() => {
    // lax.init();
    // // Add a driver that we use to control our animations
    // lax.addDriver("scrollY", function() {
    //   return window.scrollY;
    // });

    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  };

  const options = useMemo(
    () => ({
      background: {
        color: {
          // value: "#212529",
          value: "#EEF3FA",
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 10,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        color: {
          value: "#FFC007",
        },
        // links: {
        //   color: "#FFC007",
        //   distance: 150,
        //   enable: true,
        //   opacity: 0.5,
        //   width: 1,
        // },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 2,
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 80,
        },
        opacity: {
          value: 1,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 2 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          zIndex: 1,
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <AleitNavbar />
            <PreSale />
          </div>
        </div>

        <div style={{ backgroundColor: "#EEF3FA" }}>
          <MissionStatement />
        </div>

        <div>
          <EchoSystem />
        </div>
        <div style={{ backgroundColor: "#EEF3FA" }}>
          <Stages />
        </div>

        <div>
          <UpcomingExchanges />
        </div>

        <div style={{ backgroundColor: "#EEF3FA" }}>
          <TargetedExchanges />
        </div>

        <div>
          <RoadMap />
        </div>

        <div style={{ backgroundColor: "#EEF3FA" }}>
          <TokenSale />
        </div>

        <div>
          <Faqs />
        </div>
        <div style={{ backgroundColor: "#EEF3FA" }}>
          <Footer />
        </div>
      </div>

      {init ? (
        <Particles
          style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        ></Particles>
      ) : null}
    </React.Fragment>
  );
}

export default Home;
