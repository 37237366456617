import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
} from "react-bootstrap";
import SweetAlert2, { withSwal } from "react-sweetalert2";
import ReactLoading from "react-loading";
import { useTimer } from "react-timer-hook";
import { useMediaQuery } from "react-responsive";

const PresaleTimer = ({
  swal,
  connectMetaMask,
  isWalletConnected,
  airdrop,
  presale,
  activeBtns,
}) => {
  const [bnbVal, setBnbVal] = useState("0.018");
  const [usdtVal, setUsdtVal] = useState("");
  const [bnbBinanceRate, setBnbBinanceRate] = useState(0);

  const [calculatedAleit, setCalculatedAleit] = useState(0);
  const [bnbOrUsdt, setBnbOrUsdt] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [airdropLoading, setAirdropLoading] = useState(false);

  const [airdropTarget, setAirdropTarget] = useState(0);
  const [totalAirdropAleit, setTotalAirdropAleit] = useState(0);

  const [currentRound, setCurrentRound] = useState(0);
  const [presaleAmount, setPresaleAmount] = useState(0);
  const [currentPresaleTargetValue, setCurrentPresaleTargetValue] = useState(0);

  const [airdropAleit, setAirdropAleit] = useState("");
  const [presaleRound, setPresaleRound] = useState("");
  const [roundRate, setRoundRate] = useState("");
  const [presaleAleit, setPresaleAleit] = useState("");
  const [currentGrowth, setCurrentGrowth] = useState("");
  const [presaleAddress, setPresaleAddress] = useState("");
  const [airdropAddress, setAirdropAddress] = useState("");

  const [currentTarget, setCurrentTarget] = useState("");
  const expiryTimestamp = new Date("April 25 2024");
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const getBNBCurrentRate = () => {
    axios
      .get("https://api.binance.com/api/v3/avgPrice?symbol=BNBUSDT")
      .then((data) => {
        console.log("********************");
        console.log(data.data.price);
        setBnbBinanceRate(data.data.price);
        console.log("********************");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPresaleData = () => {
    axios
      .get("https://alleimran.com/api/metamask/getAleitPresaleData")
      .then((data) => {
        console.log(data.data);
        setAirdropTarget(data.data.airdropTarget);
        setTotalAirdropAleit(data.data.totalAirdropAleit);

        setCurrentRound(data.data.currentRound);
        setPresaleAmount(data.data.presale_amount);
        setCurrentPresaleTargetValue(data.data.currentTargetValue);

        setPresaleAddress(data.data.presaleAddress);
        setAirdropAddress(data.data.airdropAddress);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createTransaction = (airdropOrPresale) => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      })
      .then((result) => {
        console.log(result);
        window.ethereum
          .request({
            method: "eth_sendTransaction",
            params: [
              {
                from: `${walletAddress}`, // The user's active address.
                to:
                  airdropOrPresale == "presale"
                    ? presaleAddress
                    : airdropAddress,
                value:
                  airdropOrPresale == "presale"
                    ? Number(bnbVal * 1000000000000000000).toString(16)
                    : Number(3200000000000000).toString(16),
                gas: Number(21000).toString(16),
              },
            ],
          })
          .then((txHash) => {
            console.log(txHash);
            storeTransactionHash(txHash, airdropOrPresale);
          })
          .catch((error) => {
            if (error.code == 4001) {
              setAirdropLoading(false);
              swal.fire({
                title: "Important",
                text: "You have denied the transaction.",
                icon: "error",
              });
            }
          });
      })
      .catch((error) => {
        if (error.code === 4902) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x38",
                  chainName: "BNB Smart Chain Testnet",
                  rpcUrls: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545/"],
                  nativeCurrency: {
                    decimals: 18,
                    symbol: "tBNB",
                  },
                },
              ],
              // params: [
              //   {
              //     chainId: '0x38',
              //     chainName: 'BNB Chain',
              //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
              //     nativeCurrency:{
              //         decimals:18,
              //         symbol:"BNB"
              //     }
              //   },
              // ],
              // params: [
              //   {
              //     chainId: '0x38',
              //     chainName: 'BNB Chain',
              //     rpcUrls: ['https://bsc-dataseed.binance.org/'],
              //     nativeCurrency:{
              //         decimals:18,
              //         symbol:"BNB"
              //     }
              //   },
              // ],
            })
            .then(() => {})
            .catch((error) => {
              setAirdropLoading(false);
              console.log(error.message);
            });
        }
      });
  };

  const storeTransactionHash = (txHash, user_type) => {
    console.log(walletAddress[0]);
    console.log(user_type);
    console.log(txHash);

    let apidata = {};
    if (user_type == "presale") {
      apidata = {
        wallet_address: walletAddress[0],
        total_aleit: 0,
        presale_aleit: Math.round((bnbBinanceRate * bnbVal) / roundRate),
        bnb_rate: bnbBinanceRate,
        value_in_bnb: bnbVal,
        presale_amount:
          user_type == "presale"
            ? Number(bnbBinanceRate * bnbVal).toFixed(2)
            : 1,
        txHash: txHash,
        presale_round: presaleRound,
        target_round: currentTarget,
        round_value: roundRate,
        buying_rate: roundRate,
        user_type: user_type,
        status: "pending",
        payment_method: "bnb",
      };
    } else {
      apidata = {
        wallet_address: walletAddress[0],
        total_aleit: 100,
        presale_amount: null,
        txHash: txHash,
        buying_rate: roundRate,
        user_type: user_type,
        status: "pending",
        payment_method: "bnb",
        current_round: 1,
        current_round_value: roundRate,
        bnb_rate: bnbBinanceRate,
        value_in_bnb: bnbVal,
      };
    }

    axios({
      method: "post",
      url: "https://alleimran.com/api/metamask/storeTransactionDetails",
      data: apidata,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        console.log(res);
        setAirdropLoading(false);
        if (res.data == "yes") {
          swal.fire({
            title: "Congratulations!",
            text:
              user_type == "presale"
                ? "Successfully Participated in Presale. Please check dashboard."
                : "Successfully approved your Airdrop. Please check dashboard.",
            icon: "success",
          });
        } else {
          swal.fire({
            title: "Alas!",
            text: "Unable to hold your position in presale.",
            icon: "error",
          });
        }
      })
      .catch((error) => {
        swal.fire({
          title: "Opps!",
          text: `Your record was unable to save due to technical issue. Please take screenshot and generate a ticket.
            ${"\n"}Total Purchase : ${bnbVal}
            ${"\n"}BNB RATE : ${bnbBinanceRate}
            ${"\n"}Wallet : ${walletAddress[0]} `,
          icon: "error",
        });
        console.log(error);
      });
  };

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts.
            console.log("NO CONNECTED ACCOUNT FOUND");
          } else {
            console.log("CONNECTED ACCOUNT FOUND AIRDROP");

            console.log("*****************************");
            console.log(accounts[0]);
            setWalletAddress(accounts);
            console.log("*****************************");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getAleitPresaleData = async (walletAddress) => {
    axios
      .get("https://alleimran.com/api/metamask/getAleitPresaleData")
      .then((resp) => {
        setPresaleRound(resp.data.currentRound);
        setRoundRate(resp.data.currentAleitRate);
        setCurrentTarget(resp.data.currentTargetValue);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPresaleData();
    getBNBCurrentRate();
    checkIsMetamaskAlreadyConnected();
    getAleitPresaleData();
  }, []);

 

  return (
    <div
    style={{marginTop:isDesktopOrLaptop &&'-15%'}}
      className="polygon-card"
    >
      <h2
        className="poppinsExtraBold White-Color-Text mt-4"
        style={{ textAlign: "center" }}
      >
        {airdrop == true ? "Airdrop Sale" : "PRE-SALE"} IS LIVE
      </h2>
      {/* <Row className="mt-5">
        <Col lg={3} md={3} sm={3} xs={3}>
          <div
            className=""
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h1
              style={{ textAlign: "center" }}
              className="poppinsBold Yellow-Color-Text"
            >
              {days}
            </h1>
            <p
              style={{ textAlign: "center", fontSize: "14px" }}
              className="poppinsBold Yellow-Color-Text"
            >
              Days
            </p>
          </div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <div
            className=""
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h1
              style={{ textAlign: "center" }}
              className="poppinsBold Yellow-Color-Text"
            >
              {hours}
            </h1>
            <p
              style={{ textAlign: "center", fontSize: "14px" }}
              className="poppinsBold Yellow-Color-Text"
            >
              Hours
            </p>
          </div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <div
            className=""
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h1
              style={{ textAlign: "center" }}
              className="poppinsBold Yellow-Color-Text"
            >
              {minutes}
            </h1>
            <p
              style={{ textAlign: "center", fontSize: "14px" }}
              className="poppinsBold Yellow-Color-Text"
            >
              Minutes
            </p>
          </div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <div
            className=""
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <h1
              style={{ textAlign: "center" }}
              className="poppinsBold Yellow-Color-Text"
            >
              {seconds}
            </h1>
            <p
              style={{ textAlign: "center", fontSize: "14px" }}
              className="poppinsBold Yellow-Color-Text"
            >
              Seconds
            </p>
          </div>
        </Col>
      </Row> */}

      <Row className="mt-5">
        {airdrop == true ? (
          <Col lg={12} md={12} sm={12} xs={12}>
            <p
              // style={{ fontSize: "24px" }}
              className="poppinsRegular White-Color-Text resText"
            >
              TOTAL AIRDROP :
              <span className="poppinsExtraBold Yellow-Color-Text">
                {" "}
                {totalAirdropAleit.toFixed(2)} / {airdropTarget}
              </span>
            </p>

            <ProgressBar
              max={airdropTarget}
              animated
              variant="warning"
              now={totalAirdropAleit}
            />
            <br></br>
          </Col>
        ) : (
          <React.Fragment>
            <Col lg={12} md={12} sm={12} xs={12}>
              <p
                style={{ fontSize: "24px" }}
                className="poppinsRegular White-Color-Text"
              >
                Round {currentRound}:
                <span className="poppinsExtraBold Gradient-Dark">
                  {" "}
                  $ {presaleAmount.toFixed(2)} / $ {currentPresaleTargetValue}
                </span>
              </p>
              <ProgressBar
                max={300000}
                animated
                // variant=''
                variant="warning"
                now={presaleAmount}
              />
            </Col>
            <Col md={12} sm={12} xs={12} className="mt-3">
              <h4
                className="poppinsBold White-Color-Text"
                style={{ textAlign: "center", fontSize: "15px" }}
              >
                1 ALEIT = (${roundRate})
              </h4>
            </Col>
          </React.Fragment>
        )}
      </Row>

      {airdrop == false && presale == true ? (
        <Row className="mt-3">
          {bnbOrUsdt == "" ? (
            <React.Fragment>
              <Row>
                <Col md={7} sm={7} xs={7}>
                  <h4
                    className="poppinsBold Gradient-Dark resText"
                    style={{ textAlign: "center" }}
                  >
                    BNB BEP20
                  </h4>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img
                        src={require("../../assets/images/bnb.png")}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(event) => {
                        setBnbVal(event.target.value);
                        console.log(bnbBinanceRate);
                        console.log(event.target.value);
                        console.log(roundRate);
                        setCalculatedAleit(
                          (bnbBinanceRate * event.target.value) / roundRate
                        );
                        setBnbOrUsdt("bnb");
                      }}
                      value={bnbVal}
                      type="number"
                      placeholder="Min 0.018"
                      min="0.018"
                    />
                  </InputGroup>
                </Col>

                <Col md={5} sm={5} xs={5}>
                  <h4
                    className="poppinsBold Gradient-Dark resText"
                    style={{ textAlign: "center", }}
                  >
                    You'll receive
                  </h4>
                  <Card style={{ height: "37px", width: "100%" }}>
                    <h4
                      className="poppinsBold Yellow-Color-Text resText"
                      style={{
                        textAlign: "center",
                        // fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      ({Number(calculatedAleit).toFixed(0)} ALEIT)
                    </h4>
                  </Card>
                </Col>
              </Row>

              {/* <Col md={12} sm={12} xs={12}>
                <p
                  className="poppinsBold White-Color-Text"
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Or
                </p>
              </Col>

              <Row>
                <Col md={7} sm={7} xs={7}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img
                        src={require("../../assets/images/usdt.png")}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(event) => {
                        setUsdtVal(event.target.value);
                        setCalculatedAleit(event.target.value / roundRate);
                        setBnbOrUsdt("usdt");
                      }}
                      value={usdtVal}
                      placeholder="Min 20"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>

                <Col md={5} sm={5} xs={5}>
                  <Card style={{ height: "37px", width: "100%" }}>
                    <h4
                      className="poppinsBold Yellow-Color-Text"
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      ({Number(calculatedAleit).toFixed(0)} ALEIT)
                    </h4>
                  </Card>
                </Col>
              </Row> */}
            </React.Fragment>
          ) : bnbOrUsdt == "bnb" ? (
            <Row>
              <Col md={7} sm={7} xs={7}>
                <h4
                  className="poppinsBold Yellow-Color-Text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  BNB BEP20
                </h4>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={require("../../assets/images/bnb.png")}
                      style={{ height: "20px", width: "20px" }}
                    />
                  </InputGroup.Text>
                  <Form.Control
                    autoFocus
                    onChange={(event) => {
                      setBnbVal(event.target.value);
                      console.log(bnbBinanceRate);
                      console.log(event.target.value);
                      console.log(roundRate);
                      setCalculatedAleit(
                        (bnbBinanceRate * event.target.value) / roundRate
                      );
                      setBnbOrUsdt(event.target.value == "" ? "" : "bnb");
                    }}
                    value={bnbVal}
                    type="number"
                    placeholder="Min 0.018"
                    min="0.018"
                  />
                </InputGroup>
              </Col>

              <Col md={5} sm={5} xs={5}>
                <h4
                  className="poppinsBold Yellow-Color-Text"
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  You'll receive
                </h4>
                <Card style={{ height: "37px", width: "100%" }}>
                  <h4
                    className="poppinsBold Yellow-Color-Text"
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    ({Number(calculatedAleit).toFixed(0)} ALEIT)
                  </h4>
                </Card>
              </Col>
            </Row>
          ) : (
            <React.Fragment>
              {/* <Col md={12} sm={12} xs={12}>
                <p
                  className="poppinsBold White-Color-Text"
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Or
                </p>
              </Col>

              <Row>
                <Col md={7} sm={7} xs={7}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <img
                        src={require("../../assets/images/usdt.png")}
                        style={{ height: "20px", width: "20px" }}
                      />
                    </InputGroup.Text>
                    <Form.Control
                      autoFocus
                      onChange={(event) => {
                        setUsdtVal(event.target.value);
                        setCalculatedAleit(event.target.value / roundRate);
                        setBnbOrUsdt(event.target.value == "" ? "" : "usdt");
                      }}
                      value={usdtVal}
                      placeholder="Min 20"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>

                <Col md={5} sm={5} xs={5}>
                  <Card style={{ height: "37px", width: "100%" }}>
                    <h4
                      className="poppinsBold Yellow-Color-Text"
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        marginTop: "10px",
                      }}
                    >
                      ({Number(calculatedAleit).toFixed(0)} ALEIT)
                    </h4>
                  </Card>
                </Col>
              </Row> */}
            </React.Fragment>
          )}
        </Row>
      ) : null}

      <Row>
        {activeBtns == true ? (
          <Col md={12} sm={12} xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            {airdrop == false && presale == false ? null : null}
            {isWalletConnected ? (
              airdrop == false && presale == true ? (
                <Button
                  onClick={() => {
                    if (bnbVal != null) {
                      // createTransaction("presale");
                      if (Number(bnbVal) < 0.018) {
                        swal
                          .fire({
                            icon: "warning",
                            title: "Important!",
                            text: "You cannot pay less than 0.018 BNB.",
                            // showCancelButton: true,
                            confirmButtonText: "Ok",
                          })
                          .then((result) => {
                            setBnbVal(Number(0.018));
                          });
                      } else {
                        createTransaction("presale");
                      }
                    }
                  }}
                  className="btn btn-warning rounded-5 mt-2"
                  style={{
                    height: "43px",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <span className="poppinsSemiBold White-Color-Text">
                    {" "}
                    BUY ALEIT
                  </span>
                </Button>
              ) : airdropLoading ? (
                <div style={{ paddingLeft: "40%" }}>
                  <ReactLoading
                    type="bubbles"
                    color="#F9B708"
                    height={64}
                    width={64}
                  />
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setAirdropLoading(true);
                    createTransaction("airdrop");
                  }}
                  className="btn btn-warning rounded-5 mt-2"
                  style={{
                    height: "43px",
                    width: "100%",
                    fontSize: "14px",
                  }}
                >
                  <span className="poppinsSemiBold White-Color-Text">
                    {" "}
                    JOIN AIRDROP
                  </span>
                </Button>
              )
            ) : (
              <Button
                onClick={() => {
                  if (usdtVal == "" && bnbVal == "") {
                    swal
                      .fire({
                        icon: "warning",
                        title: "Important!",
                        text: "Please select, How would you like to pay?",
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                      })
                      .then((result) => {});
                  } else if (usdtVal != "" && bnbVal != "") {
                    swal
                      .fire({
                        icon: "warning",
                        title: "Important!",
                        text:
                          "You can pay with only one currency type. BNB or USDT. Both are not allowed.",
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                      })
                      .then((result) => {});
                  } else if (usdtVal != "" && bnbVal == "") {
                    connectMetaMask();
                  } else if (usdtVal == "" && bnbVal != "") {
                    connectMetaMask();
                  }
                }}
                className="rounded-5"
                style={{
                  height: "43px",
                  width: "50%",
                  fontSize: "14px",
                  backgroundColor:'red'
                }}
              >
                <span className="poppinsSemiBold White-Color-Text">
                  {" "}
                  CONNECT WALLET
                </span>
              </Button>
            )}
          </Col>
        ) : (
          <Col md={12} sm={12} xs={12} style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            {airdrop == false && presale == false ? null : null}
            {isWalletConnected ? (
              airdrop == false && presale == true ? (
                <Button
                  onClick={() => {
                    window.location.href = "/presale";
                  }}
                  className="btn btn-warning rounded-5 mt-2"
                  style={{
                    height: "43px",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  <span className="poppinsSemiBold White-Color-Text">
                    {" "}
                    BUY ALEIT
                  </span>
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    window.location.href = "/airdrop";
                  }}
                  className="btn btn-warning rounded-5 mt-2"
                  style={{
                    height: "43px",
                    width: "50%",
                    fontSize: "14px",
                  }}
                >
                  <span className="poppinsSemiBold White-Color-Text">
                    {" "}
                    JOIN AIRDROP
                  </span>
                </Button>
              )
            ) : (
              <Button
                onClick={() => {
                  if (usdtVal == "" && bnbVal == "") {
                    swal
                      .fire({
                        icon: "warning",
                        title: "Important!",
                        text: "Please select, How would you like to pay?",
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                      })
                      .then((result) => {});
                  } else if (usdtVal != "" && bnbVal != "") {
                    swal
                      .fire({
                        icon: "warning",
                        title: "Important!",
                        text:
                          "You can pay with only one currency type. BNB or USDT. Both are not allowed.",
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                      })
                      .then((result) => {});
                  } else if (usdtVal != "" && bnbVal == "") {
                    connectMetaMask();
                  } else if (usdtVal == "" && bnbVal != "") {
                    connectMetaMask();
                  }
                }}
                className="rounded-5 m-2"
                style={{
                  height: "43px",
                  width: "50%",
                  fontSize: "14px",
                  backgroundColor:'#ffffff',
                  borderWidth:0,
                }}
              >
                <span className="poppinsSemiBold Gradient-Text">
                  {" "}
                  CONNECT WALLET
                </span>
              </Button>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default withSwal(PresaleTimer);
