import React, { useEffect, useState } from "react";
import MasterLayout from "../master/MasterLayout";
import { Card, Col, Row, Table } from "react-bootstrap";
import axios from "axios";

const Transactionhistory = () => {
  //
  const [walletAddress, setWalletAddress] = useState([]);
  const [presaleTransactions, setPresaleTransactions] = useState([]);
  const [airdropTransactions, setAirdropTransactions] = useState([]);

  const checkIsMetamaskAlreadyConnected = async () => {
    if (window.ethereum != undefined) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected to any account
            console.log("NO CONNECTED ACCOUNT FOUND");
          } else {
            console.log("CONNECTED ACCOUNT FOUND");
            setWalletAddress(accounts[0]);
            getTransactionHistory(accounts[0]);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const getTransactionHistory = (walletAddress) => {
    axios
      .post("https://alleimran.com/api/metamask/getTransactionHistory", {
        wallet_address: walletAddress,
      })
      .then((resp) => {
        console.log(resp.data.transactions);
        setPresaleTransactions(resp.data.presaleTransactions);
        setAirdropTransactions(resp.data.airdropTransactions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkIsMetamaskAlreadyConnected();
  }, []);

  return (
    <MasterLayout>
      <Row className="mt-2">
        <Col md={12} sm={12} xs={12}>
          <h3 className="poppinsBold Yellow-Color-Text mb-4">
            Transaction Histories
          </h3>

          <h4 className="poppinsBold Yellow-Color-Text">Presale</h4>
          <Table responsive bordered hover variant="dark">
            <thead className="poppinsSemiBold">
              <tr>
                <th>#</th>
                <th>Wallet Address</th>
                <th>Transaction Hash</th>
                <th>Presale ALEIT</th>
                <th>Presale Amount</th>
                <th>Buying Rate</th>
                <th>BNB Rate</th>
                <th>Value in BNB</th>
                <th>Payment Method</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="poppinsRegular">
              {presaleTransactions.map((item) => {
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.wallet_address}</td>
                    <td>{item.txHash}</td>
                    <td>{item.presale_aleit}</td>
                    <td>{item.presale_amount}</td>
                    <td>{item.buying_rate}</td>
                    <td>{item.bnb_rate}</td>
                    <td>{item.value_in_bnb}</td>
                    <td>{item.payment_method}</td>
                    <td>{item.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <h4 className="poppinsBold Yellow-Color-Text mt-5">Airdrop</h4>
          <Table responsive bordered hover variant="dark">
            <thead className="poppinsSemiBold">
              <tr>
                <th>#</th>
                <th>Wallet Address</th>
                <th>Total ALEIT</th>
                <th>Transaction Hash</th>
                <th>BNB Rate</th>
                <th>Value in BNB</th>
                <th>Payment Method</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="poppinsRegular">
              {airdropTransactions.map((item) => {
                return (
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.wallet_address}</td>
                    <td>{item.total_aleit}</td>
                    <td>{item.txHash}</td>
                    <td>{item.bnb_rate}</td>
                    <td>{item.value_in_bnb}</td>
                    <td>{item.payment_method}</td>
                    <td>{item.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </MasterLayout>
  );
};

export default Transactionhistory;
