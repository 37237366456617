import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";

import { Button, Container, ProgressBar } from "react-bootstrap";
import { useState } from "react";
import AnimationOnScroll from "../../components/AnimationOnScroll/AnimationOnScroll";

function MissionStatement() {
  const missionImage = require("../../assets/images/mission.png");
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    console.log("CALLED");
    const handleScroll = () => {
      const isScrolled = window.scrollY > 5000;
      console.log(isScrolled);
      setAnimate(true);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <React.Fragment>
      <Container id="mission">
        <div className="row p-5">
          <div className="col-md-5 col-xs-12">
            <AnimationOnScroll direction={"leftToRight"}>
              <img
                src={missionImage}
                className={`img img-fluid 
               ${""}
              `}
              />
            </AnimationOnScroll>
          </div>
          <div className="col-md-1 col-xs-12"></div>
          <div className="col-md-6 col-xs-12 mt-5">
            <p
              className="poppinsBold Black-Color-Text"
              // style={{ textAlign: "center" }}
            >
              MISSION
            </p>
            <h1
              // className="poppinsExtraBold Black-Color-Text"
              className="poppinsSemiBold Gradient-Text"
              // className="poppinsSemiBold Gradient-Text"
              // style={{ textAlign: "center" }}
            >
              Our OC2B Mission
            </h1>
            <p
              className="poppinsRegular Black-Color-Text resText "
              style={{  textAlign: "justify" }}
            >
              Our mission is to revolutionize the customer experience by
              providing a seamless platform that rewards users with crypto
              tokens for every purchase. We are dedicated to empowering
              customers with financial benefits, ensuring they earn a handsome
              amount at the end of each month through our innovative cashback
              system. By leveraging the power of ALEIT, we aim to create a
              community where every transaction becomes an opportunity for
              customers to not only enjoy their purchases but also reap
              significant cashback rewards. Our commitment lies in redefining
              the way people shop, offering them a pathway to financial
              prosperity through the exciting world of Cashback.
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default MissionStatement;
