import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import "react-vertical-timeline-component/style.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../App.css";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

function TargetedExchanges() {
  const targeted = [
    require("../../assets/images/Exchanges/gate.jpg"),
    require("../../assets/images/Exchanges/huobi.jpg"),
    require("../../assets/images/Exchanges/kucoin.jpg"),
    require("../../assets/images/Exchanges/mexc.jpg"),
    require("../../assets/images/Exchanges/okex.jpg"),
  ];
  const isMobile = useMediaQuery({ query: "(max-width: 475px)" });


  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    lazyLoad: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div
        // className="mb-5"
        style={{ paddingInline: "8%", paddingBlock: "6%", marginBottom: "7%" }}
      >
        <h2
          className="poppinsBold Gradient-Text mb-5"
          style={{ textAlign: "center" }}
        >
          Targeted Exchanges
        </h2>
        <Row className="justify-content-md-center">
          <Slider {...settings}>
            {targeted.map((item, index) => {
              return (
                <div key={index}>
                  <div style={{ margin: 5 }}>
                    <Image
                      src={item}
                      thumbnail
                      className="p-3"
                      style={{

                        width:isMobile?"500px":'250px',
                        height: "120px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </Row>
      </div>
    </React.Fragment>
  );
}
export default TargetedExchanges;
