import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "react-vertical-timeline-component/style.min.css";
import "../../App.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaSuitcase } from "react-icons/fa";
import { LuScaling } from "react-icons/lu";
import { RiListIndefinite, RiArticleLine } from "react-icons/ri";

import { Button, Container, ProgressBar } from "react-bootstrap";
import { red } from "@mui/material/colors";

function RoadMap() {
  const missionImage = require("../../assets/images/mission.png");

  return (
    <React.Fragment>
      <Container id="roadmap" fluid className="pt-5 lightGradient-Background">
        <h2
          className="poppinsBold Black-Color-Text mb-5"
          style={{ textAlign: "center" }}
        >
          Company Roadmap
        </h2>
        <div className="row p-5">
          <div className="col-md-12 col-xs-12">
            <VerticalTimeline>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#D7CCDF",
                  color: "#fff",
                  borderBottomWidth: "5px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#AF99BF",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #AF99BF ",
                }}
                iconStyle={{ background: "#AF99BF", color: "#fff" }}
                icon={<FaSuitcase />}
              >
                <h3
                  className="poppinsExtraBold Black-Color-Text"
                  style={{ fontSize: "22px" }}
                >
                  QUARTER-2 (2024)
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "10px" }}>
                  <p>1- Website Launch</p>
                  <p>2- Initiate Presale Rounds</p>
                  <p>3- Airdrop campaign</p>
                  <p>4- Complete Audit</p>
                  <p>5- Publish Whitepaper</p>
                  <p>6- Expanding influencer Marketing</p>
                  <p>7- Reach 10000 Aleit holders</p>
                </h4>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#D7CCDF",
                  color: "#fff",
                  borderBottomWidth: "5px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#AF99BF",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #AF99BF ",
                }}
                iconStyle={{ background: "#AF99BF", color: "#fff" }}
                icon={<RiArticleLine />}
              >
                <h3
                  className="poppinsExtraBold Black-Color-Text"
                  style={{ fontSize: "22px" }}
                >
                  QUARTER-3 (2024)
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  <p>1- Initiate Presale (2nd last Round)</p>
                  <p>2- Media Promotion</p>
                  <p>3- Publish News Articles</p>
                  <p>4- Initiate Presale (Final Round)</p>
                  <p>5- Achieve 50000 Aleit holders</p>
                </h4>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#D7CCDF",
                  color: "#fff",
                  borderBottomWidth: "5px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#AF99BF",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #AF99BF ",
                }}
                iconStyle={{ background: "#AF99BF", color: "#fff" }}
                icon={<RiListIndefinite />}
              >
                <h3
                  className="poppinsExtraBold Black-Color-Text"
                  style={{ fontSize: "22px" }}
                >
                  QUARTER-4 (2024)
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  <p>1- Ecosystem Scaling</p>
                  <p>2- Listing on Dex</p>
                  <p>3- Listing on CMC/CG</p>
                  <p>4- Intensify YouTube Marketing</p>
                  <p>5- Listing on Centralized Exchanges</p>
                  <p>6- Trending on Twitter</p>
                  <p>7- Surpass 100000 Aleit Holders</p>
                </h4>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{
                  background: "#D7CCDF",
                  color: "#fff",
                  borderBottomWidth: "5px",
                  borderBottomStyle: "solid",
                  borderBottomColor: "#AF99BF",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid #AF99BF ",
                }}
                iconStyle={{ background: "#AF99BF", color: "#fff" }}
                icon={<LuScaling />}
              >
                <h3
                  className="poppinsExtraBold Black-Color-Text"
                  style={{ fontSize: "22px" }}
                >
                  QUARTER-1 (2025)
                </h3>
                <h4 className="poppinsSemiBold" style={{ fontSize: "12px" }}>
                  <p>1- Initiate Market Making</p>
                  <p>2- Listing on Targeted Exchanges</p>
                  <p>3- Enhance Social Media Marketing</p>
                  <p>4- Collaboration with Businesses</p>
                  <p>5- Achieve 200000 Aleit holders</p>
                </h4>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default RoadMap;
